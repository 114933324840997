import React, { PropsWithChildren, useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";
import { graphql, StaticQuery, navigate } from "gatsby";
import { layoutState } from "../../../contexts/layoutState";
import {
  useSpotlight,
  SpotlightProvider,
  SpotlightAction,
} from "@mantine/spotlight";
import * as styles from "./searchBar.module.scss";
import { SearchData } from "src/utils/search";
import { Input, Kbd } from "@mantine/core";
import {
  IconFileDescription,
  IconHeading,
  IconSearch,
  IconTags,
  IconUser,
} from "@tabler/icons-react";

export type RawResult = SearchData;
export type DedeupedResult = Pick<
  SearchData,
  "id" | "type" | "title" | "searchRef"
> & { topics?: string[] };

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`;

export const SearchBarProvider: React.FC<PropsWithChildren> = (props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      return (
        <SearchBarSpotlight
          index={data.localSearchPages.index}
          store={data.localSearchPages.store}
        >
          {props.children}
        </SearchBarSpotlight>
      );
    }}
  />
);
const NoResults = () => <span>No results found for this query. </span>;

const MoreCharactersPrompt = () => (
  <span>Search queries must be longer than two characters.</span>
);
const dedupeResults = (searchableResults: RawResult[]): DedeupedResult[] => {
  let dedupedResults = {};

  searchableResults.forEach((i) => {
    const dedupeRef = `${i.id}-${i.type}`;
    if (!dedupedResults[dedupeRef]) {
      dedupedResults[dedupeRef] = {
        title: i.title,
        type: i.type,
        id: i.id,
        searchRef: dedupeRef,
      };
    }
    if (i.type === "topic" && dedupedResults[dedupeRef]) {
      dedupedResults[dedupeRef].topics
        ? dedupedResults[dedupeRef].topics.push(i.indexValue)
        : (dedupedResults[dedupeRef].topics = [i.indexValue]);
    }
  });

  return Object.entries(dedupedResults).map((i) => i[1] as DedeupedResult);
};

const transformDedupedResultToSpotlightAction = (
  results: DedeupedResult[],
  sitemapLinkDictionary: object
): SpotlightAction[] => {
  const transformed: SpotlightAction[] = results
    .filter((r) => !!sitemapLinkDictionary[r.id])
    .map((r) => {
      const sitemapLinkDictionaryEntry = sitemapLinkDictionary[r.id];
      return {
        title: r.title,
        id: r.searchRef,
        group:
          r.type === "title"
            ? "Page Titles"
            : r.type === "heading"
            ? "Headings"
            : r.type === "topic"
            ? "Topics"
            : r.type, // fall through to personas
        icon:
          r.type === "title" ? (
            <IconFileDescription />
          ) : r.type === "heading" ? (
            <IconHeading />
          ) : r.type === "topic" ? (
            <IconTags />
          ) : (
            <IconUser /> // fall through to personas
          ),
        path: sitemapLinkDictionaryEntry.path,
        onTrigger: () => {
          navigate(sitemapLinkDictionaryEntry.path);
        },
      };
    });
  return transformed;
};

export const SearchBarUI: React.FC = () => {
  const { openSpotlight } = useSpotlight();
  return (
    <div id={styles.searchTarget}>
      <Input
        icon={<IconSearch size={18} />}
        placeholder="Search"
        size="md"
        radius={"sm"}
        rightSection={<Kbd>/</Kbd>}
        onClick={openSpotlight}
        onFocus={(e) => {
          // essentially forward a ref
          e.target.blur();
          openSpotlight();
        }}
      />
    </div>
  );
};

const SearchBarSpotlight = (props) => {
  const [query, setQuery] = useState<string>("");
  const results = useFlexSearch(query, props.index, props.store, {
    suggest: true,
    limit: 24,
  });
  const { sitemapLinkDictionary, breadCrumbDictionary } =
    React.useContext(layoutState);
  // memoize to prevent unwanted rerenders which break the arrow cursor-ing of results
  const searchableResults = React.useMemo(
    // filter out pages when the links CAN'T be resolved
    () =>
      results.filter(
        (i) => !!sitemapLinkDictionary[i.id] && !!breadCrumbDictionary[i.id]
      ) as RawResult[],
    [results]
  );

  const dedeupedResults: DedeupedResult[] = React.useMemo(
    // filter out pages when the links CAN'T be resolved
    () => dedupeResults(searchableResults),
    [searchableResults]
  );

  const actions = transformDedupedResultToSpotlightAction(
    dedeupedResults,
    sitemapLinkDictionary
  );

  return (
    <SpotlightProvider
      actions={actions}
      filter={() => actions}
      query={query}
      highlightQuery
      highlightColor="blue"
      onQueryChange={(v) => setQuery(v)}
      searchIcon={<IconSearch size="1.2rem" />}
      searchPlaceholder="Search..."
      transitionProps={{ duration: 200, transition: "slide-down" }}
      shortcut="/"
      nothingFoundMessage={
        query.length > 1 ? <NoResults /> : <MoreCharactersPrompt />
      }
    >
      {props.children}
    </SpotlightProvider>
  );
};
