import { ContentfulContentOutlet } from "../../graphql-types";
import { validateNavigationSection } from "./navigationSection/validator";
import { validatePage } from "./page/validator";

export const isSitemapPageEntry = (entry: any) => {
  const validPage =
    entry.__typename === "ContentfulPage" ? validatePage(entry) : undefined;
  return validPage;
};

export const isNavigationEntry = (entry: any) => {
  const validPage =
    entry.__typename === "ContentfulNavigationSection"
      ? validateNavigationSection(entry)
      : undefined;
  return validPage;
};

export const isContentOutlet = (section: any) => {
  if (section && section.contentOutletId) {
    return section as ContentfulContentOutlet;
  }
  return undefined;
};
