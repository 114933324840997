/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/index.scss";

import * as React from "react";
import { Root } from "./src/layout/root";
export const wrapRootElement = ({ element }) => {
  return <Root>{element}</Root>;
};

export const disableCorePrefetching = () => true;
