import * as yup from "yup";

const EntrySchema = yup.object({
  contentful_id: yup.string().required(),
  __typename: yup.string().required(),
});

export type ValidatedEntry = yup.InferType<typeof EntrySchema>;

export const validateEntry = (entry: any, msg?: string) => {
  try {
    return EntrySchema.validateSync(entry);
  } catch (reason) {
    throw new Error(
      `A malformed Entry was detected
      ${entry?.__typename ? entry.__typename : ""} ${
        entry?.contentful_id ? entry.contentful_id : ""
      } ${msg ? `\n${msg}` : ""}\n ${reason.errors.join("\n")}`
    );
  }
};
