import * as React from "react";
/**
 * THIS IS THE LINK CONTEXT USED TO PROVIDE THE LINK DICTIONARY TO CHILDREN WHO MAY NEED IT
 */
export const layoutState = React.createContext({
  sideNavStatesDictionary: {},
  sitemapLinkDictionary: {},
  breadCrumbDictionary: {},
  isOpenState: {
    isOpen: true,
    setIsOpen: (_: boolean) => {},
  },
});
