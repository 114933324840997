import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import React from "react";

const brandPrimary: ColorType = [
  "#F0F9FC",
  "#D5F1FB",
  "#A2E5FB",
  "#51D7FC",
  "#13C2E8",
  "#0EA6C8",
  "#0A8AA6",
  "#087992",
  "#045264",
  "#05343F",
];

const orangeOverride: ColorType = [
  "#FCF6F3",
  "#FAE8E0",
  "#F9D1BE",
  "#F9B796",
  "#F8A47A",
  "#F89361",
  "#F37E44",
  "#E6601A",
  "#C75215",
  "#953C0E",
];

export const theme: MantineThemeOverride = {
  colors: {
    "brand-primary": brandPrimary,
    orange: orangeOverride,
  },
  primaryColor: "brand-primary",
  primaryShade: 7,

  headings: {
    fontFamily: "'Noto Sans', sans-serif",
  },
  fontFamily: "'Noto Sans', sans-serif",
};

export const Theme = ({ children }) => (
  <MantineProvider
    withCSSVariables
    withGlobalStyles
    withNormalizeCSS
    theme={theme}
  >
    {children}
  </MantineProvider>
);

type ColorType = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];
