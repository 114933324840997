import * as yup from "yup";
import { PageSchema } from "../page/validator";

const NavigationSectionSchema = yup.object({
  contentful_id: yup.string().required(),
  __typename: yup.string().required(),
  rootPage: PageSchema.required(),
  navigation: yup.array().required().min(1),
});

export type ValidatedNavigationSection = yup.InferType<
  typeof NavigationSectionSchema
>;

export const validateNavigationSection = (entry: any) => {
  try {
    const validNavigationSection = NavigationSectionSchema.validateSync(entry);
    return validNavigationSection;
  } catch (reason) {
    throw new Error(
      `The NavigationSection ${
        entry.contentful_id
      } had validation errors: \n ${reason.errors.join("\n")}`
    );
  }
};
