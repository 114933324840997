import { Actions } from "gatsby";
import { ValidatedNavigationSection } from "src/graphql-and-validation/navigationSection/validator";
import { ValidatedPageWithoutContent } from "src/graphql-and-validation/page/validator";

export const outletId = "fhir-resources";
const baseSlug = "fhir-resources";

const mapToPath = (type: string, nameOrCode: string) => {
  switch (nameOrCode) {
    case "read":
    case "update":
      return `/${type}/{id}`;
    case "search-type":
      return `/${type}/_search`;
    case "create":
      return `/${type}`;
    default:
      return `/${type}/${nameOrCode}`;
  }
};

const mapToVerb = (nameOrCode: string) => {
  switch (nameOrCode) {
    case "read":
      return "GET";
    case "update":
      return "PUT";
    default:
      return `POST`;
  }
};

/**
 * @param FHIRResources The FHIR resources pulled from the GraphQL query
 * @returns
 */
export const mapToSitemap = (
  FHIRResources: Array<
    Queries.MasterSiteMapQuery["allFhirResource"]["edges"][number]["node"]
  >
): ValidatedNavigationSection => {
  const FHIRResourcesRootPage = {
    title: "FHIR® resources",
    slug: baseSlug,
    contentful_id: `fhir-resources-landing-page`,
    id: `fhir-resources-landing-page`,
    __typename: "ContentfulPage",
  } as ValidatedPageWithoutContent;
  // There's quite a bit of casting going on here because of the mapping to a incomplete type
  const FHIRNavigationSection: ValidatedNavigationSection[] = [];
  FHIRResources.forEach((fhirModel) => {
    const subNavigation: ValidatedPageWithoutContent[] = [];
    // create an entry for every interaction using the model's id and interaction code
    fhirModel.interaction?.forEach((i) => {
      const code = i?.code || "unknown";
      const transformedCode = code === "search-type" ? "_search" : code;
      subNavigation.push({
        contentful_id: `${fhirModel.id}-${code}`,
        slug: `${transformedCode}`,
        title: `${transformedCode}`,
        __typename: "ContentfulPage",
      } as ValidatedPageWithoutContent);
    });
    // create an entry for every operation using the model's id and operation name
    fhirModel.operation?.forEach((o) => {
      const opPath = o?.path || "unknown";
      subNavigation.push({
        contentful_id: `${fhirModel.id}-${opPath}`,
        slug: `${opPath}`,
        title: `${opPath}`,
        __typename: "ContentfulPage",
      } as ValidatedPageWithoutContent);
    });
    // create an entry for each model (it's "landing page" or "root page")
    const ModelRootPage = {
      contentful_id: `${fhirModel.id}`,
      slug: `${fhirModel.type}`,
      title: `${fhirModel.type}`,
      __typename: "ContentfulPage",
    } as ValidatedPageWithoutContent;
    FHIRNavigationSection.push({
      contentful_id: `${fhirModel.id}-navigation-section`,
      rootPage: ModelRootPage,
      navigation: subNavigation,
      __typename: "ContentfulNavigationSection",
    } as ValidatedNavigationSection);
  });
  // result is mapped to contentful derived shape and can be processed by our functions
  return {
    contentful_id: `fhir-resources-navigation-section`,
    rootPage: FHIRResourcesRootPage,
    navigation: FHIRNavigationSection,
    __typename: "ContentfulNavigationSection",
  } as ValidatedNavigationSection;
};

export const createPages = (
  FHIRResources: Queries.Query["allFhirResource"],
  path: string,
  createPageFn: Actions["createPage"],
  createRedirectFn: Actions["createRedirect"],
  pathFn
) => {
  createPageFn({
    path: `${path}/${baseSlug}`,
    context: {
      id: `fhir-resources-landing-page`,
    },
    component: pathFn.resolve(
      "./src/pageTemplates/fhir/FHIRResourceAndOperationTableOfContents.tsx"
    ),
  });

  createRedirectFn({
    fromPath: `/permalink/fhir-resources-landing-page`,
    toPath: `${path}/${baseSlug}`,
  });

  FHIRResources.edges.forEach(({ node }) => {
    const options = [
      ...(node.interaction
        ? node.interaction.map((i) => ({
            value: i?.code === "search-type" ? "_search" : i?.code,
            type: "interaction",
          }))
        : []),
      ...(node.operation
        ? node.operation.map((op) => ({
            value: op?.path,
            type: "operation",
          }))
        : []),
    ];
    // create base page that will redirect
    createPageFn({
      path: `${path}/${baseSlug}/${node.type}`,
      component: pathFn.resolve(
        `./src/pageTemplates/fhir/FHIRResourceRedirect.tsx`
      ),
      context: {
        redirectTo: `${path}/${baseSlug}/${node.type}/${options[0].value}`,
      },
    });

    // create interactions
    if (node.interaction) {
      node.interaction.forEach((i) => {
        const transformedCode =
          i?.code === "search-type" ? "_search" : i?.code || "unknown";
        createPageFn({
          path: `${path}/${baseSlug}/${node.type}/${transformedCode}`,
          component: pathFn.resolve(
            `./src/pageTemplates/fhir/FHIRResource.tsx`
          ),
          context: {
            type: node.type,
            fhirPath: mapToPath(node.type || "unknown", transformedCode),
            verb: mapToVerb(transformedCode),
            name: { value: transformedCode, type: "interaction" },
            tab: transformedCode,
            id: node.id,
            code: i?.code,
          },
        });

        createRedirectFn({
          fromPath: `/permalink/${node.id}-${i?.code}`,
          toPath: `${path}/${baseSlug}/${node.type}/${transformedCode}`,
        });
      });
    }
    // create operations
    if (node.operation) {
      node.operation.forEach((op) => {
        const opPath = op?.path || "unknown";
        createPageFn({
          path: `${path}/${baseSlug}/${node.type}/${opPath}`,
          component: pathFn.resolve(
            `./src/pageTemplates/fhir/FHIROperation.tsx`
          ),
          context: {
            type: node.type,
            fhirPath: mapToPath(node?.type || "unknown", opPath),
            verb: mapToVerb(opPath),
            id: node.id,
            name: opPath,
          },
        });

        createRedirectFn({
          fromPath: `/permalink/${node.id}-${opPath}`,
          toPath: `${path}/${baseSlug}/${node.type}/${opPath}`,
        });
      });
    }
  });
};
