import * as yup from "yup";

const ContentSchema = yup.object({
  contentful_id: yup.string().required(),
  __typename: yup
    .string()
    .matches(/ContentfulLandingPage|ContentfulGenericContent|ContentfulAction/)
    .required(),
});

export const PageSchema = yup.object({
  contentful_id: yup.string().required(),
  __typename: yup
    .string()
    .matches(/ContentfulPage/)
    .required(),
  title: yup.string().required(),
  slug: yup.string().required(),
  content: yup.object().optional(),
  personas: yup.array(yup.string()).optional().nullable(),
});

export const validateContent = (entry: any) => {
  try {
    return ContentSchema.validateSync(entry);
  } catch (reason) {
    throw new Error(
      `The Content Entry ${
        entry.contentful_id
      } had validation errors: \n ${reason.errors.join("\n")}`
    );
  }
};

export type ValidatedContent = yup.InferType<typeof ContentSchema>;

export const validatePage = (entry: any) => {
  try {
    return PageSchema.validateSync(entry);
  } catch (reason) {
    throw new Error(
      `The Page "${entry.title}" (${
        entry.contentful_id
      }) had validation errors: \n ${reason.errors.join("\n")}`
    );
  }
};

export type ValidatedPage = yup.InferType<typeof PageSchema>;
export type ValidatedPageWithoutContent = Omit<ValidatedPage, "content">;
