import {
  isSitemapPageEntry,
  isNavigationEntry,
  isContentOutlet,
} from "../graphql-and-validation/sitemapConditionalValidation";
import { validateEntry } from "../graphql-and-validation/entry/validator";
import { ValidatedNavigationSection } from "../graphql-and-validation/navigationSection/validator";
import { ContentOutlets } from "../types";

/**
 * A function that will create breadcrumbs entries for a given page. The entries will be an
 * array of pageId and not yet hydrated with paths and titles
 * @param section the current item being worked on (page or navigation section)
 * @param accumulator the object that is collecting the flatmap of breadcrumbs by pageId
 * @param previous the array of previous pages in the tree
 */
const createBreadCrumbEntry = (
  section: any,
  accumulator: { [key: string]: string[] },
  previous: string[]
) => {
  const validatedEntry = validateEntry(section);
  const page = isSitemapPageEntry(validatedEntry);
  if (page) {
    accumulator[page.contentful_id] = [...previous, page.contentful_id];
  }

  const navigationSection = isNavigationEntry(validatedEntry);
  if (navigationSection) {
    accumulator[navigationSection.rootPage.contentful_id] = [
      ...previous,
      navigationSection.rootPage.contentful_id,
    ];

    navigationSection.navigation.forEach((s) => {
      createBreadCrumbEntry(s, accumulator, [
        ...previous,
        navigationSection.rootPage.contentful_id,
      ]);
    });
  }
};

/**
 *
 * @param topLevelNavigation the validated and typed topLevel of query results
 * @param sitemapLinkDictionary the output of recursivelyCreateLinksDictionary
 * @returns a complete map of hydrated breadcrumbs entries by page id
 */
export const createBreadCrumbDictionary = (
  topLevelNavigation: any,
  sitemapLinkDictionary
) => {
  const breadcrumbHierarchy = {};
  topLevelNavigation.navigation.forEach((section) => {
    createBreadCrumbEntry(section, breadcrumbHierarchy, [
      topLevelNavigation.rootPage.contentful_id,
    ]);
  });
  // hydrate with paths and titles
  const breadcrumbDictionaryByContentfulId = {};
  for (const [id, hierarchy] of Object.entries(breadcrumbHierarchy)) {
    breadcrumbDictionaryByContentfulId[id] = (hierarchy as string[]).map(
      (id) => sitemapLinkDictionary[id]
    );
  }
  return breadcrumbDictionaryByContentfulId;
};

/**
 * This function creates all of the links in the site by calling the recursive function
 * @param topLevelSection The top level of the sitemap hierarchy
 * @returns the completed dictionary of Links
 */
export const createLinksDictionary = (topLevelSection: any) => {
  const sitemapLinkDictionary = {};
  sitemapLinkDictionary[topLevelSection.rootPage.contentful_id] = {
    path: "/",
    title: "Home",
  };
  topLevelSection.navigation.forEach((section) =>
    recursivelyCreateLinksDictionary(section, sitemapLinkDictionary, "")
  );

  return sitemapLinkDictionary;
};

/**
 * A function that evaluates a section, creates an entry for any pages in that section
 * and then recurses to the next section should it contain children
 * @param section the current item being worked on (page or navigation section)
 * @param accumulator the object that is collecting the flatmap of links by pageId
 * @param currentPath
 */
const recursivelyCreateLinksDictionary = (
  section: any,
  accumulator: { [key: string]: { path: string; title: string } },
  currentPath?: string
) => {
  const validatedEntry = validateEntry(section);
  const page = isSitemapPageEntry(validatedEntry);
  if (page) {
    accumulator[page.contentful_id] = {
      path: `${currentPath}/${page.slug}`,
      title: page.title || "",
    };
  }

  const navigationSection = isNavigationEntry(validatedEntry);
  if (navigationSection) {
    accumulator[navigationSection.rootPage.contentful_id] = {
      path: `${currentPath}/${navigationSection.rootPage.slug}`,
      title: navigationSection.rootPage.title || "",
    };

    navigationSection.navigation.forEach((s) =>
      recursivelyCreateLinksDictionary(
        s,
        accumulator,
        `${currentPath}/${navigationSection.rootPage.slug}`
      )
    );
  }
};

/**
 * This function is designed to count all of the pages in a given navigation section
 * and recursively traverse their depth to do so
 * @param navigationSection the section currently being worked on. Can contain any
 * number of depth of nested children
 * @param subPageCountArray the count of all the pages in each section as an array.
 * this is reduced later
 * @returns the unreduced array of page counts to be reduced by caller
 */
const getPagesInSection = (
  navigationSection: ValidatedNavigationSection,
  subPageCountArray: number[] = []
) => {
  subPageCountArray.push(navigationSection.navigation.length);
  navigationSection.navigation.forEach((i) => {
    const validatedEntry = validateEntry(i);
    const childNavSection = isNavigationEntry(validatedEntry);
    if (childNavSection) {
      getPagesInSection(childNavSection, subPageCountArray);
    }
  });
  return subPageCountArray;
};

/**
 * This function traverse the tree and finds all of the leaves (pages) and creates
 * an entry for them with the proper format
 * @param section the current item being worked on (page or navigation section)
 * @returns an object describing part of a sidenav entry
 */
const createSideNavEntryForPage = (section: any) => {
  const validatedEntry = validateEntry(section);
  const page = isSitemapPageEntry(validatedEntry);
  if (page) {
    return {
      title: page.title,
      id: page.contentful_id,
    };
  }
  const navigationSection = isNavigationEntry(validatedEntry);
  if (navigationSection) {
    return {
      title: navigationSection.rootPage.title,
      id: navigationSection.rootPage.contentful_id,
      // these branching sections also calculate all the pages in the section
      // and create immediate children
      pagesInThisSection: getPagesInSection(navigationSection).reduce(
        (p, c) => p + c
      ),
      children: navigationSection.navigation.map((s) =>
        createSideNavEntryForPage(s)
      ),
    };
  }
  // something must be malformed to arrive here
  return undefined;
};

/**
 * This function traverses the tree of pages and creates the sidenav state for a given page.
 * It will recurse when it encounters children
 * @param section the current item being worked on (page or navigation section)
 * @param accumulator the flatmap of all the sideNav State by pageId
 */
export const createSideNavDataForAllPages = (
  section: any,
  accumulator = {}
) => {
  const validatedEntry = validateEntry(section);
  const navigationSection = isNavigationEntry(validatedEntry);
  if (navigationSection) {
    const thisSectionSideNav = {
      title: navigationSection.rootPage.title,
      children: navigationSection.navigation.map((s) =>
        createSideNavEntryForPage(s)
      ),
    };
    accumulator[navigationSection.rootPage.contentful_id] = thisSectionSideNav;
    navigationSection.navigation.forEach((s) => {
      const validatedSectionItem = validateEntry(s);
      const page = isSitemapPageEntry(validatedSectionItem);
      if (page) {
        accumulator[page.contentful_id] = thisSectionSideNav;
      }

      const navigationSection = isNavigationEntry(validatedSectionItem);
      if (navigationSection) {
        createSideNavDataForAllPages(navigationSection, accumulator);
      }
    });
  }
  return accumulator;
};

/**
 * Be aware this function performs a mutation of the original object
 * TODO don't mutate the original object
 * @param section any iterable section of the sitemap
 * @param contentOutlets the map of contentOutlet function closed over their data
 */
export const hydrateContentOutlets = (
  section: any,
  contentOutlets: ContentOutlets
) => {
  const validatedEntry = validateEntry(section);
  const navigationSection = isNavigationEntry(validatedEntry);
  if (navigationSection) {
    navigationSection.navigation.forEach((s, index, array) => {
      const validatedSectionItem = validateEntry(s);
      const contentOutlet = isContentOutlet(validatedSectionItem);
      if (
        contentOutlet?.contentOutletId &&
        contentOutlets[contentOutlet.contentOutletId]
      ) {
        const newContent = validateEntry(
          contentOutlets[contentOutlet.contentOutletId](),
          `The content outlet ${contentOutlet.contentOutletId} has a malformed normalized output. The mapToSitemap function is likely returning an unexpected result.`
        );

        if (isNavigationEntry(newContent) || isSitemapPageEntry(newContent)) {
          array[index] = newContent;
        }
      }

      const childNavigationSection = isNavigationEntry(s);
      if (childNavigationSection) {
        hydrateContentOutlets(childNavigationSection, contentOutlets);
      }
    });
  }
};
