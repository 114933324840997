import { Actions } from "gatsby";
import { ValidatedPageWithoutContent } from "src/graphql-and-validation/page/validator";
import { ValidatedNavigationSection } from "src/types";
import { startCase, camelCase } from "lodash";

export const outletId = "fhir-notifications";
const baseSlug = "fhir-notifications";

export const mapToSitemap = (
  WebhookPaths: any,
  WebhookTopics: any
): ValidatedNavigationSection => {
  const FhirWebhooksRootPage = {
    title: "FHIR notifications",
    slug: baseSlug,
    contentful_id: `fhir-notification-landing-page`,
    id: `fhir-notification-landing-page`,
    __typename: "ContentfulPage",
  } as ValidatedPageWithoutContent;

  // There's quite a bit of casting going on here because of the mapping to a incomplete type
  const FhirWebhookApiNavigationSection: ValidatedNavigationSection[] = [];

  WebhookTopics.forEach((webhookTopic) => {
    const subNavigation: ValidatedPageWithoutContent[] = [];

    // create an entry for each Contentful content model (it's "landing page" or "root page")
    const ModelRootPage = {
      contentful_id: `${webhookTopic.id}`,
      slug: `${webhookTopic.openAPI}`,
      title: `${webhookTopic.openAPI}`,
      __typename: "ContentfulPage",
    } as ValidatedPageWithoutContent;

    // create a subnav entry for each path that has this tag
    WebhookPaths.forEach((webhookPath) => {
      const topic = webhookPath.topic;
      const trigger = webhookPath.trigger;
      if (topic === webhookTopic.openAPI) {
        subNavigation.push({
          contentful_id: `${webhookPath.id}-${trigger}`,
          slug: `${webhookPath.trigger}`,
          title: `${startCase(camelCase(webhookPath.trigger))}`,
          __typename: "ContentfulPage",
        } as ValidatedPageWithoutContent);
      }
    });
    FhirWebhookApiNavigationSection.push({
      contentful_id: `${webhookTopic.id}-navigation-section`,
      rootPage: ModelRootPage,
      navigation: subNavigation,
      __typename: "ContentfulNavigationSection",
    } as ValidatedNavigationSection);
  });

  // result is mapped to contentful derived shape and can be processed by our functions
  return {
    contentful_id: `fhir-notification-navigation-section`,
    rootPage: FhirWebhooksRootPage,
    navigation: FhirWebhookApiNavigationSection,
    __typename: "ContentfulNavigationSection",
  } as ValidatedNavigationSection;
};

export const createPages = (
  WebhookPaths: any,
  path: string,
  createPageFn: Actions["createPage"],
  createRedirectFn: Actions["createRedirect"],
  pathFn
) => {
  // create landing page
  createPageFn({
    path: `${path}/${baseSlug}`,
    context: {
      id: `fhir-notification-landing-page`,
    },
    component: pathFn.resolve(
      "./src/pageTemplates/fhirWebhooks/webhookAPITableOfContents.tsx"
    ),
  });

  createRedirectFn({
    fromPath: `/permalink/fhir-notification-landing-page`,
    toPath: `${path}/${baseSlug}`,
  });

  // create pages for each category
  WebhookPaths.data.allWebhookTopic.edges.forEach(({ node }: any) => {
    const category = node.openAPI;
    const options: string[] = [];

    WebhookPaths.data.allWebhookPath.edges.forEach(({ node }: any) => {
      const pathArray = JSON.parse(node.openAPI);
      const trigger = node.trigger;

      if (pathArray[0].post["x-eventTopic"] === category) {
        createPageFn({
          path: `${path}/${baseSlug}/${category}/${trigger}`,
          component: pathFn.resolve(
            `./src/pageTemplates/fhirWebhooks/webhookResource.tsx`
          ),
          context: {
            type: category,
            id: `${node.id}-${trigger}`,
            pathName: node.path,
            trigger: trigger,
          },
        });

        createRedirectFn({
          fromPath: `/permalink/${node.id}-${trigger}`,
          toPath: `${path}/${baseSlug}/${category}/${trigger}`,
        });

        options.push(trigger);
      }
    });

    createPageFn({
      path: `${path}/${baseSlug}/${category}`,
      component: pathFn.resolve(
        `./src/pageTemplates/fhirWebhooks/webhookResourceRedirect.tsx`
      ),
      context: {
        redirectTo: `${path}/${baseSlug}/${category}/${options[0]}`,
      },
    });
  });
};
