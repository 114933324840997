import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { layoutState } from "../contexts/layoutState";
import { useSitemap } from "./useSitemap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SearchBarProvider } from "../components/topnav/searchBar";
import { Theme } from "./theme";

export const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const state = useSitemap();
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            reCaptchaSiteKey
          }
        }
      }
    `
  );
  const reCaptchaKey = data.site.siteMetadata.reCaptchaSiteKey;
  const common = (
    <Theme>
      <layoutState.Provider value={state}>
        <SearchBarProvider>{children}</SearchBarProvider>
      </layoutState.Provider>
    </Theme>
  );
  if (reCaptchaKey !== "local") {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        {common}
      </GoogleReCaptchaProvider>
    );
  }
  return common;
};
