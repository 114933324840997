import { Actions } from "gatsby";
import { ValidatedPageWithoutContent } from "src/graphql-and-validation/page/validator";
import { ValidatedNavigationSection } from "src/types";
import { kebabCase } from "lodash";

export const outletId = "platform-api-reference";
const baseSlug = "platform-api-reference";

export const mapToSitemap = (
  PlatformTags: any,
  PlatformPaths: any
): ValidatedNavigationSection => {
  const PlatformResourcesRootPage = {
    title: "Platform API",
    slug: baseSlug,
    contentful_id: `platform-api-landing-page`,
    id: `platform-api-landing-page`,
    __typename: "ContentfulPage",
    personas: [],
  } as ValidatedPageWithoutContent;

  // There's quite a bit of casting going on here because of the mapping to a incomplete type
  const PlatformApiNavigationSection: ValidatedNavigationSection[] = [];

  PlatformTags.forEach((platformResource) => {
    const subNavigation: ValidatedPageWithoutContent[] = [];

    // create an entry for each Contentful content model (it's "landing page" or "root page")
    const ModelRootPage = {
      contentful_id: `${platformResource.id}`,
      slug: `${kebabCase(platformResource.name)}`,
      title: `${platformResource.name}`,
      __typename: "ContentfulPage",
    } as ValidatedPageWithoutContent;

    // create a subnav entry for each path that has this tag
    PlatformPaths.forEach((platformPath) => {
      const tag = platformPath.tags[0];
      const operation = kebabCase(platformPath.operationId);
      if (tag === platformResource.name) {
        subNavigation.push({
          contentful_id: `${platformPath.id}-${operation}`,
          slug: `${platformPath.operation}`,
          title: `${platformPath.summary}`,
          __typename: "ContentfulPage",
        } as ValidatedPageWithoutContent);
      }
    });
    PlatformApiNavigationSection.push({
      contentful_id: `${platformResource.id}-navigation-section`,
      rootPage: ModelRootPage,
      navigation: subNavigation,
      __typename: "ContentfulNavigationSection",
    } as ValidatedNavigationSection);
  });

  // result is mapped to contentful derived shape and can be processed by our functions
  return {
    contentful_id: `platform-api-navigation-section`,
    rootPage: PlatformResourcesRootPage,
    navigation: PlatformApiNavigationSection,
    __typename: "ContentfulNavigationSection",
  } as ValidatedNavigationSection;
};

export const createPages = (
  PlatformPaths: any,
  path: string,
  createPageFn: Actions["createPage"],
  createRedirectFn: Actions["createRedirect"],
  pathFn
) => {
  // create landing page
  createPageFn({
    path: `${path}/${baseSlug}`,
    context: {
      id: `platform-api-landing-page`,
    },
    component: pathFn.resolve(
      "./src/pageTemplates/platform/platformAPITableOfContents/index.tsx"
    ),
  });

  createRedirectFn({
    fromPath: `/permalink/platform-api-landing-page`,
    toPath: `${path}/${baseSlug}`,
  });

  PlatformPaths.data.allPlatformApiResource.edges.forEach(({ node }: any) => {
    const tagName = node.name;
    const urlSafeTagName = kebabCase(tagName);
    const options: string[] = [];
    PlatformPaths.data.allPlatformApiPath.edges.forEach(({ node }: any) => {
      if (node.tags[0] === tagName) {
        const operation = kebabCase(node.operationId);

        // create individual pages
        createPageFn({
          path: `${path}/${baseSlug}/${urlSafeTagName}/${operation}`,
          component: pathFn.resolve(
            `./src/pageTemplates/platform/platformResource.tsx`
          ),
          context: {
            type: tagName,
            id: `${node.id}-${operation}`,
            pathName: node.path,
            operationId: node.operationId,
            operation: operation,
          },
        });

        createRedirectFn({
          fromPath: `/permalink/${node.id}-${operation}`,
          toPath: `${path}/${baseSlug}/${urlSafeTagName}/${operation}`,
        });

        options.push(operation);
      }
    });

    // create "tag" redirect to first path
    createPageFn({
      path: `${path}/${baseSlug}/${urlSafeTagName}`,
      component: pathFn.resolve(
        `./src/pageTemplates/platform/platformResourceRedirect.tsx`
      ),
      context: {
        redirectTo: `${path}/${baseSlug}/${urlSafeTagName}/${options[0]}`,
      },
    });
  });
};
