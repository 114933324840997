import { Actions } from "gatsby";
import { ValidatedNavigationSection } from "src/graphql-and-validation/navigationSection/validator";
import { ValidatedPageWithoutContent } from "src/graphql-and-validation/page/validator";

export const outletId = "data-models";
const baseSlug = "datamodels";

/**
 * @param FHIRResources The FHIR resources pulled from the GraphQL query
 * @returns
 */
export const mapToSitemap = (
  allDataModels: Array<any>
): ValidatedNavigationSection => {
  const FHIRResourcesRootPage = {
    title: "Redox Data Models",
    slug: baseSlug,
    contentful_id: `data-model-landing-page`,
    id: `data-model-landing-page`,
    __typename: "ContentfulPage",
    personas: [],
  } as ValidatedPageWithoutContent;
  // There's quite a bit of casting going on here because of the mapping to a incomplete type
  const DMNavigationSection: ValidatedNavigationSection[] = [];
  allDataModels
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach(
      (dataModel: {
        id: string;
        name: string;
        children: Array<{ id: string; name: string }>;
      }) => {
        const subNavigation: ValidatedPageWithoutContent[] = [];
        // create an entry for every interaction using the model's id and interaction code
        dataModel.children?.forEach((i) => {
          subNavigation.push({
            contentful_id: `${i.id}`,
            slug: `${i.id}`,
            title: `${i.name}`,
            __typename: "ContentfulPage",
          } as ValidatedPageWithoutContent);
        });
        // create an entry for each model (it's "landing page" or "root page")
        const ModelRootPage = {
          contentful_id: `${dataModel.id}`,
          slug: `${dataModel.id}`,
          title: `${dataModel.name}`,
          __typename: "ContentfulPage",
        } as ValidatedPageWithoutContent;

        DMNavigationSection.push({
          contentful_id: `${dataModel.id}-navigation-section`,
          rootPage: ModelRootPage,
          navigation: subNavigation,
          __typename: "ContentfulNavigationSection",
        } as ValidatedNavigationSection);
      }
    );
  // result is mapped to contentful derived shape and can be processed by our functions
  return {
    contentful_id: `data-model-api-reference-redirect`,
    rootPage: FHIRResourcesRootPage,
    navigation: DMNavigationSection,
    __typename: "ContentfulNavigationSection",
  } as ValidatedNavigationSection;
};

export const createPages = (
  dataModelContent: any,
  path: string,
  createPageFn: Actions["createPage"],
  createRedirectFn: Actions["createRedirect"],
  pathFn
) => {
  createRedirectFn({
    fromPath: `/permalink/data-model-landing-page`,
    toPath: `${path}/${baseSlug}`,
  });

  createRedirectFn({
    fromPath: `/data-models`,
    toPath: `${path}/${baseSlug}`,
  });

  createPageFn({
    path: `${path}/${baseSlug}`,
    context: {
      id: `data-model-landing-page`,
    },
    component: pathFn.resolve("./src/pageTemplates/datamodels/landing.tsx"),
  });

  dataModelContent.data.allDataModel.edges.forEach(({ node }: any) => {
    createRedirectFn({
      fromPath: `/data-models/${node.name.replace(" ", "")}.html`,
      toPath: `${path}/${baseSlug}/${node.id}`,
    });

    createRedirectFn({
      fromPath: `/data-models/${node.name.replace(" ", "")}`,
      toPath: `${path}/${baseSlug}/${node.id}`,
    });

    createRedirectFn({
      fromPath: `/permalink/${node.id}`,
      toPath: `${path}/${baseSlug}/${node.id}`,
    });

    createPageFn({
      path: `${path}/${baseSlug}/${node.id}`,
      component: pathFn.resolve(`./src/pageTemplates/datamodels/datamodel.tsx`),
      context: {
        id: `${node.id}`,
      },
    });

    node.children.forEach((child: any) => {
      createRedirectFn({
        fromPath: `/permalink/${child.id}`,
        toPath: `${path}/${baseSlug}/${node.id}/${child.id}`,
      });
      createPageFn({
        path: `${path}/${baseSlug}/${node.id}/${child.id}`,
        component: pathFn.resolve(
          `./src/pageTemplates/datamodels/datamodel-event.tsx`
        ),
        context: {
          id: `${child.id}`,
          parent: `${node.id}`,
        },
      });
    });
  });
};
